import { COLORS } from "@/styles/colors";

import { Stack, Text, Title } from "@mantine/core";
import type { NextPage } from "next";
import Image from "next/image";
import Link from "next/link";

import WhiteButton from "@/components/atoms/WhiteButton";

const Custom404: NextPage = () => {
  return (
    <Stack pt={80} align="center">
      <Title order={1} sx={{ color: COLORS.rakushifuBlue, fontSize: 64 }}>
        404
      </Title>
      <Image
        src="/images/penguin.png"
        height={164}
        width={101}
        alt=""
        priority
        style={{
          maxWidth: "100%",
          height: "auto",
          objectFit: "contain",
        }}
      />
      <Text size="lg" weight="bold" sx={{ color: COLORS.rakushifuBlue }}>
        このページは存在しません
      </Text>
      <WhiteButton component={Link} href="/" sx={{ width: 200 }}>
        らくしふ労務管理に戻る
      </WhiteButton>
    </Stack>
  );
};

export default Custom404;
